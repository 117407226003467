import {SurveyJSON} from "Survey/types"

const json: SurveyJSON = {
  success: 'Færdig! Bemærk at du kun hører fra os, hvis vi er interesserede, så ikke vent på os.',
  allowNewCustomer: true,
  /*
  Fbtracking feature:
  calculateTrackingAmount?: (values: Values) => number, //if specified, this function will be used to set the value of trackingAmount
  trackingAmount?: number, //if trackingAmout value is present and positive, it will be send to facebook
  */
  calculateTrackingAmount: (values) => {
    if (values.hourly && !isNaN(values.hourly) && values.hourly < 360 && (values.competences && (values.competences.indexOf('bookkeeping') >= 0 || values.competences.indexOf('accounting') >= 0))) {
      return 300;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && (values.competences.indexOf('onboarding') >= 0 || values.competences.indexOf('customer_service') >= 0))) {
      return 150;
    } else if (values.hourly && !isNaN(values.hourly) && values.hourly < 160 && (values.competences && values.competences.indexOf('marketing') >= 0)) {
      return 100;
    } else {
      return 0; //Meaning that no purchase conversion will be reported to Facebook
    }
  },
  errors: {
    required: 'Udfyld venligst dette'
  },
  locales: {
    dk: {
      nextButton: 'Fortsæt',
      nextButtonHint: 'tryk <b>Enter ↵</b>',
      submitButton: 'Indsend',
      textAreaHint: '<b>Shift ⇧</b> + <b>Enter ↵</b> to make a line break',
      invalidEmail: "Email adressen er ugyldig"
    }
  },
  defaultLocale: 'dk',
  items: [
    {
      type: 'checkboxes',
      name: 'erfaring_med_afstemning',
      title: 'Hvor stammer din erfaring med afstemning fra?',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Fra en revisor- eller bogholdervirksomhed, hvor jeg har udført afstemninger for forskellige virksomheder',
          value: 'fra_bogholderivirksomhed',
        }, {
          text: 'Fra en virksomhed, jeg arbejdet for',
          value: 'arbejde_for_virksomhed',
        }, {
          text: 'Fra min egen virksomhed',
          value: 'egen_virksomhed',
        }, {
          text: 'Jeg har ingen erfaring, men er god til tal og hurtig til at lære',
          value: 'ingen_erfaring',
        },
      ]
    }, {
      if: (values) => (values.erfaring_med_afstemning && (values.erfaring_med_afstemning.indexOf('ingen_erfaring') < 0)),
      type: 'checkboxes',
      name: 'hvor_erfaring_fra',
      title: 'Hvilke typer af afstemning har du erfaring med? Markér alle relevante valg:',
      required: false,
      multiple: true,
      options: [
        {
          text: 'Afstemning af bank og bilag',
          value: 'bankafstemning',
        }, {
          text: 'Afstemning af løn og E-indkomst\n',
          value: 'løn',
        }, {
          text: 'Afstemning af skattekonto',
          value: 'skattekonto',
        }, {
          text: 'Afstemning af kreditorer og debitorer',
          value: 'kreditorer_debitorer',
        },
      ]
    },{
      type: 'input',
      name: 'name',
      title: 'Hvad er dit navn',
      required: true,
      placeholder: 'Jens Jensen',
    },{
      type: 'input',
      inputType: 'email',
      name: 'email',
      title: 'Hvad er din email adresse?',
      required: true,
      placeholder: 'email@email.com',
    }, {
      type: 'input',
      name: 'hours',
      title: 'Hvor mange timer ønsker du at arbejde per uge i vores højsæson der går fra 1. januar til 1. september',
      required: true,
      placeholder: '40',
      mask: '999',
    },{
      type: 'input',
      name: 'minimum_hours',
      title: 'Hvad er det mindste timetal per uge, du kan acceptere i perioder med lavere travlhed?',
      required: true,
      placeholder: '0',
      mask: '999',
    },{
      type: 'input',
      name: 'when_start',
      title: 'Hvornår vil du kunne starte hos Revisor1?',
      required: true,
      placeholder: 'Første marts',
    },{
      type: 'checkboxes',
      name: 'salary_or_freelancer',
      title: 'Er du kun interesseret i at arbejde som lønmodtager, eller også som freelancer hvor du fakturerer os for de timer du arbejder. Markér det du er interesseret i',
      required: true,
      multiple: true,
      options: [
        {
          text: 'Jeg er interesseret i at arbejde som freelancer hvor jeg fakturerer per time, i hvert fald i starten',
          value: 'freelancer',
        }, {
          text: 'Jeg er interesseret i at arbejde som lønmodtager',
          value: 'salary',
        },
      ]
    }, {
      if: (values) => (values.salary_or_freelancer && (values.salary_or_freelancer.indexOf('freelancer') >= 0)),
      type: 'input',
      name: 'hourly',
      title: 'Hvis du arbejdede som freelancer, hvor mange kroner tager du i såfald per time (eks. evt. moms)?',
      required: false,
      placeholder: '100',
      mask: '999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    },{
      if: (values) => (values.salary_or_freelancer && (values.salary_or_freelancer.indexOf('salary') >= 0)),
      type: 'input',
      name: 'monthly',
      title: 'Hvis du arbejdede som lønmodtager på fuldtid, hvor meget ville du ønske at få i bruttoløn per måned?',
      required: false,
      placeholder: '20000',
      mask: '99999',
      validate: (values) => { return (!values.hourly || isNaN(values.hourly) ? "A number is required" : "")}
    }, {
    type: 'custom',
    name: 'submit',
    html: '<blockquote>Tryk "indsend" for at sende os svarene.</blockquote>',
    buttons: ['submit']
    }
  ]
}

export default json
